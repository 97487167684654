<template>
    <div class="category-area pt--70">
        <div class="container">
            <div class="row g-5">
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="200" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="/explore-decisions">
                        <i class="feather-layout"></i>
                        <span class="category-label">Decision Reports</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="300" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="/school-browsing">
                        <i class="feather-book-open"></i>
                        <span class="category-label">School Browsing</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="400" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="/explore?search=">
                        <i class="feather-map"></i>
                        <span class="category-label">Program Browsing</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="500" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="/program-selection">
                        <i class="feather-cpu"></i>
                        <span class="category-label">AI Matching</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="600" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="/my-applications">
                        <i class="feather-grid"></i>
                        <span class="category-label">My Applications</span>
                    </a>
                </div>
                <!-- end single category -->
                <!-- start single category -->
                <div class="col-lg-3 col-xl-2 col-md-4 col-sm-6" data-sal-delay="700" data-sal="slide-up"
                     data-sal-duration="800">
                    <a class="category-style-one" href="/community">
                        <i class="feather-globe"></i>
                        <span class="category-label">Community</span>
                    </a>
                </div>
                <!-- end single category -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CategoryStyleOne'
    }
</script>
